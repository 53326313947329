.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.toolbar {
  flex: none;
}

.chart {
  flex: auto;
}

.indicator {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
