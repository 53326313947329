.main {
  height: 100%;
  display: flex;
  overflow: hidden;
}

.project {
  flex: none;
  width: 100%;
  width: 500px;
  margin-right: 10px;
}
.container {
  flex: auto;
  width: 0;
}
