@import "theme/palette.scss";

.main {
  height: 100%;
}

.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_icon {
    font-size: 24px;
    opacity: 0.75;
  }

  &_text {
    font-size: 11px;
    color: $secondary-text-color;
  }
}

.avatar_image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: auto;
  height: 50px;
  background-size: cover;
  padding: 0px;
}

.title {
  flex: auto;
  display: flex;
  align-items: center;
  padding-left: 5px;

  &_text {
    color: $primary-text-color;
    font-size: 18px;
    font-weight: 400;
    margin-left: 10px;
  }

  &_icon {
    color: yellow;
    font-size: 20px;
    opacity: 0.75;
  }

  &_description {
    flex: auto;
    display: flex;
    //align-items: center;
    // color: $secondary-color;
    // font-size: 14px;
    // font-weight: 300;
    margin-left: 35px;

    &_name {
      font-size: 14px;
      color: $secondary-color;
    }

    &_org {
      font-size: 14px;
      margin-left: 5px;
      color: $secondary-text-color;
    }
  }
}

.priority {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &_icon {
    font-size: 10px;
  }

  &_text {
    font-size: 11px;
    color: $secondary-text-color;
  }
}
