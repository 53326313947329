$background-color: #121212;
$surface-color: #1e1e1e;
$menu-color: #292929;
$textbox-color: #323232;
$selection-color: #353535;
$line-color: #373737;
$primary-color: #98c21f;
$secondary-color: #bb86fc;
$primary-text-color: #ffffff;
$secondary-text-color: rgba(255, 255, 255, 0.6);
