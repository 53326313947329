@import "../palette.scss";

.theme-datagrid {
  //
  &-title {
    margin-right: 5px;

    //color: $secondary-text-color;
  }

  // rows
  .dx-datagrid .dx-row-lines > td {
    border-bottom: 1px solid $line-color;
    font-size: 14px;
    font-weight: 100;
  }

  .dx-datagrid-text-content {
    font-weight: 400;
    font-size: 14px;
  }

  // filter text editor
  .dx-texteditor-input {
    color: $secondary-color !important;
  }

  .dx-icon dx-icon-filter-operation-default {
    color: $secondary-color !important;
  }

  // filter icons

  .dx-menu-item .dx-icon {
    font-size: 17px !important;
    color: $secondary-color !important;
  }

  // header panel

  .dx-toolbar-items-container {
    background-color: $menu-color !important;
  }

  .dx-datagrid-header-panel {
    padding: 0;
    padding-left: 10px;
    background-color: $menu-color;
    // border-bottom: none;
  }

  .dx-datagrid-header-panel .dx-toolbar-items-container {
    height: 40px;
    font-size: 17px !important;
  }

  .dx-datagrid-header-panel .dx-toolbar .dx-button-content .dx-icon {
    font-size: 17px !important;
    color: $primary-color;
  }

  // group

  .dx-datagrid-table .dx-row .dx-command-expand.dx-datagrid-group-space {
    padding-top: 0px !important;
  }
}
