@import "theme/palette.scss";

.main {
  height: 100%;
  display: flex;
}

.container {
  flex: 1;
  height: 100%;
  margin-left: 10px;
}

.footer {
  flex: none;
  display: flex;
  height: 100%;
  height: 50px;
  align-items: center;
  background-color: $textbox-color;

  &_count {
    flex: auto;
    display: flex;
    justify-content: flex-end;
    font-size: 12px;
    padding-right: 10px;
  }

  &_filter {
    flex: auto;
    justify-self: flex-end;
    font-size: 12px;
    color: $secondary-color;
    padding-left: 10px;
  }
}

.tree {
  flex: none;
  height: 100%;
  display: flex;
  width: 300px;
  border-color: none;
}

.row {
  display: flex;
  flex-direction: column;
  flex: auto;
  width: 100px;
}

.col {
  flex: auto;
  height: 100px;
}

.title {
  margin-right: 5px;
  font-size: 17px;
}

.scenario {
  margin-right: 5px;
  font-size: 12px;
}

.filter_builder {
  height: 100%;
  display: flex;
  flex-direction: column;
}
