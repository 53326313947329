@import "./palette.scss";

.theme {
  //
  &-grid-title {
    color: $secondary-text-color;
    font-size: 17px;
    font-weight: 400;
    font-family: "Roboto";
  }
}
