@import "theme/palette.scss";

.main {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: $background-color;
}

.header {
  flex: none;
}

.container {
  height: 100%;
  flex: auto;
  padding: 10px 10px 0px 5px;
}

.content {
  flex: auto;
}

.footer {
  flex: none;
  display: flex;
  height: 25px;

  &_left {
    flex: 1;
    display: flex;
    padding-left: 5px;
    align-items: center;
  }

  &_right {
    flex: 1;
    display: flex;
    align-items: center;
    padding-right: 10px;
    justify-content: flex-end;
    font-size: 10px;
  }
}

.tree {
  display: flex;
  flex-direction: column;

  &_item {
    flex: auto;
    height: 100px;
  }
}
