@import "theme/palette.scss";

.main {
  height: 100%;
  padding-left: 15px;
  padding-right: 15px;
}

.grid {
  height: 100%;
  display: grid;
  grid-template-columns: repeat(20, 1fr);
  grid-template-rows: 275px repeat(20, 1fr);
  grid-gap: 25px;
}

.banner {
  grid-column-start: 1;
  grid-column-end: 21;
  grid-row-start: 1;
  grid-row-end: 2;
  margin-left: -25px;
  margin-right: -25px;
  padding-bottom: 25px;
  overflow: hidden;

  &_background {
    @extend .banner;
    align-self: center;
    // object-fit: contain;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &_content {
    @extend .banner;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;

    &_logo {
      margin-left: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &_title {
      margin-left: 10px;
      font-size: 40px;
      text-shadow: 1px 1px 2px black;
      font-weight: 1000;
    }
  }
}

.background {
  background-color: $surface-color;
}

.cards {
  grid-column-start: 1;
  grid-column-end: 21;
  grid-row-start: 2;
  grid-row-end: 5;

  &_content {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-gap: 25px;
    //padding-top: 10px;
    //padding-bottom: 10px;
  }
}

.condition {
  grid-column-start: 17;
  grid-column-end: 21;
  grid-row-start: 5;
  grid-row-end: 22;
  grid-gap: 25px;

  display: grid;
  grid-template-rows: repeat(2, 1fr);
  overflow: hidden;

  &_item {
    @extend .background;
  }
}

.risk {
  @extend .background;
  grid-column-start: 12;
  grid-column-end: 17;
  grid-row-start: 5;
  grid-row-end: 22;
}

.line_charts {
  @extend .background;
  grid-column-start: 5;
  grid-column-end: 12;
  grid-row-start: 5;
  grid-row-end: 22;
  //padding: 25px;
}

.links {
  @extend .background;
  grid-column-start: 1;
  grid-column-end: 5;
  grid-row-start: 5;
  grid-row-end: 22;
}

.loading {
  grid-column-start: 1;
  grid-column-end: 21;
  grid-row-start: 5;
  grid-row-end: 22;
  align-self: center;
  justify-self: center;
}
