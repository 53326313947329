@import "theme/palette.scss";

.klient-layout-header {
  z-index: 1;

  .toolbar {
    background-color: #292929;
    padding-left: 20px;
    padding-right: 10px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05), 0 1px 2px rgba(255, 255, 255, 0.2);

    .menu .dx-item-content .dx-icon {
      color: $primary-color;
    }

    .title {
      margin: 0;
      margin-top: 7px;
      padding-bottom: 5px;
      margin-left: 0px;
      height: auto;
      //width: 100px;
      font-size: 24px;
      font-weight: 100;
      //border: 1px solid blue;

      .image {
        //max-width: 100%;
        //max-height: 100%;
        // margin: auto;
        // display: block;
      }
    }

    .toolbar-label,
    .toolbar-label > b {
      font-size: 20px;
    }

    .messages .dx-item-content .dx-icon {
      font-size: 20px;
    }

    .settings .dx-item-content .dx-icon {
      font-size: 20px;
    }
  }

  &-logo {
    margin-left: 0px;
    flex: auto;
    //height: 50px;
    width: auto;
  }

  &-organization {
    margin-top: 5px;
    padding-bottom: 5px;
    margin-left: 5px;
    height: auto;
    font-size: 28px;
    font-weight: 300;
  }
}
