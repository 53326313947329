// .main {
//   height: 100%;
//   display: flex;
//   flex-direction: column;
// }

// .container {
//   flex: 1;
//   height: 100px;
// }

.main {
  height: 100%;
  display: flex;
}

.row {
  display: flex;
  flex-direction: column;
  flex: auto;
  width: 100px;
}

.col {
  flex: auto;
  height: 100px;
}
