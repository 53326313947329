.main {
  height: 100%;
  display: flex;
}

.list {
  flex: none;
  width: 350px;
  margin-right: 10px;
}

.container {
  flex: auto;
  display: flex;
  flex-direction: column;
}

.grid {
  flex: auto;
  display: flex;
  &_row {
    flex: auto;
    width: 10px;
  }
}

.chart {
  margin-top: 10px;
  flex: none;
  height: 350px;
}
