.main {
  height: 100%;
  display: flex;
}

.fund {
  flex: none;
  width: 350px;
  margin-left: 10px;
}

.container {
  flex: auto;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.chart2 {
  flex: auto;
}

.chart1 {
  flex: none;
  height: 340px;
}
