@import "theme/palette.scss";

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chart {
  flex: auto;
  padding: 10px 10px 0px 10px;
  //align-self: center;
}

.title {
  flex: none;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: rgba(#fff, 0.5);
  font-weight: 400;
  border-bottom: 1px solid rgba(#fff, 0.1);
}

.note {
  flex: none;
  height: 12px;
  font-size: 11px;
  color: $secondary-text-color;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0px 5px 5px 0px;
}
