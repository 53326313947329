@import "theme/palette.scss";

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.tabs {
  flex: none;
  background-color: $menu-color;
}

.container {
  flex: auto;
  margin-top: 10px;
}
