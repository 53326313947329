.main {
  height: 100%;
  display: flex;
}

.group {
  flex: none;
  width: 350px;
}

.strategy {
  flex: auto;
}
