@import "theme/palette.scss";

.main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.tabs {
  flex: 0;
  background-color: $menu-color;
}
.container1 {
  flex: 1;
  margin-top: 10px;
  display: flex;
}

.scenario {
  flex: none;
  width: 350px;
  //margin-left: 10px;
}

.container2 {
  flex: 1;
}
