.main {
  height: 100%;
}
.icon {
  flex: auto;
  font-size: 50px;
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &_box {
    flex: auto;
  }

  img {
    display: block;
    margin: auto;
  }
}
