.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chart {
  flex: auto;
  margin-top: 25px;
}

.loading {
  flex: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
