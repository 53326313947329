.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  flex: auto;
  height: 100px;
}

.message {
  margin-bottom: 10px;
}

.indicator {
  flex: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
