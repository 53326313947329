.main {
  height: 100%;
  // border: 1px solid gray;
  display: flex;
}

.form {
  flex: auto;
}

.gauge {
  flex: none;
}

.indicator {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
