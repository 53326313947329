@import "theme/palette.scss";

.main {
  height: 100%;
  margin: 25px 0px 0px 0px;
}

.header {
  flex: none;
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  background-color: $menu-color;
  padding-left: 10px;

  &_avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: auto;
    height: 30px;
    background-size: cover;
    padding: 0px;
  }

  &_subject {
    padding-left: 10px;
    display: flex;
    &_name {
      font-size: 14px;
      color: $secondary-color;
    }

    &_org {
      font-size: 14px;
      margin-left: 5px;
      color: $secondary-text-color;
    }
  }

  &_createdon {
    flex: auto;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    color: $secondary-text-color;
  }

  &_button {
    font-size: 10px;
  }
}

.body {
  flex: auto;
}
