.main {
  display: flex;
  height: 100%;
}

.list {
  flex: none;
  width: 350px;
  margin-right: 10px;
}

.container {
  height: 100%;
  flex: auto;
}

.container2 {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.item1 {
  flex: auto;
}

.item2 {
  flex: none;
  height: 340px;
}
