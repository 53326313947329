@import "../palette.scss";

.theme-treelist {
  //
  // rows
  .dx-treelist .dx-treelist-cell-expandable {
    white-space: nowrap;
    border-bottom: 1px solid $line-color;
  }

  .dx-treelist-text-content {
    font-size: 14px;
  }

  // header
  .dx-treelist-header-panel {
    padding: 0;
    padding-left: 10px;
    background-color: $menu-color;
  }

  .dx-toolbar-items-container {
    background-color: $menu-color;
  }

  .dx-treelist-header-panel .dx-toolbar-items-container {
    height: 40px;
  }

  .dx-treelist-header-panel .dx-toolbar .dx-button-content .dx-icon {
    font-size: 17px;
    color: $primary-color;
  }

  // filter icon
  .dx-menu-item .dx-icon {
    font-size: 17px !important;
    color: $secondary-color !important;
  }

  // group

  .dx-treelist-table .dx-row .dx-command-expand.dx-datagrid-group-space {
    padding-top: 0px !important;
  }
}
