.main {
  height: 100%;
  display: flex;
}

.scenario {
  flex: none;
  width: 350px;
}

.fund {
  flex: none;
  width: 350px;
  margin-left: 10px;
}

.container {
  flex: auto;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}

.revenue {
  flex: auto;
}

.profile {
  flex: none;
  height: 350px;
  margin-top: 10px;
}

.indicator {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
