@import "theme/palette.scss";

.klient_layout_side {
  //padding: 5px;

  &_treeview {
    height: 100%;
    padding: 10px 5px 0px 10px;

    .dx-treeview {
      white-space: nowrap;
      background-color: $surface-color;

      //  Icon width customization
      .dx-treeview-item {
        padding-left: 10px;
        padding-right: 0;

        .dx-icon {
          //width: 60px !important;
          margin: 0 !important;
          margin-right: 20px !important;
        }
      }

      // Arrow customization
      .dx-treeview-node {
        padding: 0 0 !important;
      }

      .dx-treeview-toggle-item-visibility {
        right: 10px;
        left: -100px;
      }

      .dx-rtl .dx-treeview-toggle-item-visibility {
        left: 10px;
        right: auto;
      }

      // Item levels customization
      .dx-treeview-node {
        color: rgba(255, 255, 255, 0.7);

        &[aria-level="1"] {
          font-weight: 400;
          font-size: 17px;
          border-top: 1px solid $line-color;

          //border-bottom: 1px solid $border-color;
          .dx-icon {
            font-size: 17px;
          }
        }

        &[aria-level="2"] .dx-treeview-item-content {
          font-weight: 200;
          font-size: 14px;
          padding: 0 10px; //  $side-panel-min-width;
          //border-bottom: 1px solid #373737;

          .dx-icon {
            font-size: 15px;
          }
        }
      }

      //Selected & Focuced items customization
      .dx-treeview-node-container {
        .dx-treeview-node {
          &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
            background: transparent;
          }

          &.dx-state-selected > .dx-treeview-item * {
            color: $primary-color;
            //color: #009688;
            //@extend .dx-theme-accent-as-text-color;
          }

          &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
            background-color: $line-color;
          }
        }
      }
    }

    // .dx-theme-generic .dx-treeview {
    //   .dx-treeview-node-container .dx-treeview-node.dx-state-selected.dx-state-focused > .dx-treeview-item * {
    //     color: inherit;
    //   }
    // }
  }
}
