@import "theme/palette.scss";

.components_tileview {
  .dx-toolbar .dx-toolbar-items-container {
    height: 40px;
    background-color: $menu-color;
  }

  .dx-button-has-icon .dx-icon {
    font-size: 17px !important;
    color: $primary-color;
  }

  // .dx-gallery-item {
  //   height: ;: 500px;
  //   max-width: 100%;
  // }

  .dx-gallery-item img {
    height: 475px;
    width: auto;
    //max-width: 100%;
  }
}
