* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.app_start_main {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // background-image: url("./assets/images/11.jpg");
  // background-repeat: no-repeat, repeat;
  // background-position: center;
  // background-size: cover;
}

.app_start_text {
  font-size: 24px;
  letter-spacing: 1px;
}

.app_start_transparent {
  color: transparent;
}

.app_start_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 20px 0px;
}
