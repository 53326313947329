.main {
  height: 100%;
  display: flex;
}

.class {
  flex: none;
  width: 350px;
}

.size {
  flex: auto;
  margin-left: 10px;
}
