@import "../palette.scss";

.theme-form {
  .dx-field-item {
    padding-bottom: 0px;
  }

  .dx-field-item-label-location-top {
    padding-top: 0px;
  }

  .dx-texteditor.dx-editor-filled {
    background-color: $textbox-color;
  }
}

.dx-field-item-label-text {
  font-size: 14px;
  color: $secondary-text-color;
  font-weight: 400;
}
.dx-texteditor-input {
  color: $primary-text-color !important;
  font-size: 15px;
  font-weight: 100;
}
