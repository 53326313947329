.box {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  //padding: 10px 10px 0px 10px;

  &:hover {
    opacity: 0.5;
  }
}
