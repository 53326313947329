.main {
  height: 100%;
  display: flex;
}

.list {
  flex: none;
  width: 250px;
}

.container {
  flex: auto;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.expression {
  flex: auto;
  border: 1px solid black;
  padding: 0px 10px 0px 10px;
  margin-bottom: 10px;
}

.buttons {
  flex: none;
  display: flex;
  // justify-content: flex-end;
  // align-items: center;
  height: 50px;
  border: 1px solid black;
  padding: 0px 10px 0px 10px;

  &_left {
    flex: auto;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &_right {
    flex: auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &_cancel {
    margin-left: 10px;
  }
}
