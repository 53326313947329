.main {
  height: 100%;
  //display: flex;
}

// .tree {
//   flex: none;
//   width: 350px;
// }

// .container {
//   flex: auto;
//   display: flex;
//   flex-direction: column;
//   padding-left: 5px;
// }

.title {
  flex: none;
  font-weight: 100;
  font-size: 24px;
  margin-left: 15px;
}

.form {
  flex: auto;
  display: flex;
  //height: 300px;
  //margin-top: 10px;
  padding-bottom: 10px;
}

.form_fields {
  flex: auto;
  margin-left: 25px;
}

.form_avatar {
  flex: none;
  margin: 15px auto auto 15px;

  img {
    height: 130px;
  }
}

.cancelbutton {
  margin-left: 10px;
}
