@import "theme/palette.scss";

.main {
  height: 100%;
  display: flex;
}

.list {
  flex: none;
  width: 350px;
  //margin-right: 10px;
}

.progress {
  flex: 1;
  margin-left: 10px;
  //margin-top: 10px;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $surface-color;
  padding: 10px 10px 10px 10px;

  //overflow: hidden;

  &_attributes {
    flex: none;
    height: 275px;
    margin-left: 50px;
  }

  &_chart {
    flex: auto;
  }
}
