.main {
  height: 100%;
  display: flex;
}

.endpoints {
  flex: none;
  width: 350px;
  margin-right: 10px;
}

.assets {
  height: 100%;
  flex: auto;
}
