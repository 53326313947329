@import "theme/palette.scss";

.main {
  background-color: $menu-color;
  border-bottom: 1px solid rgba(#fff, 0.05);
  border-right: 1px solid rgba(#fff, 0.05);
}

.grid {
  display: flex;
}

.line {
  width: 4px;
  border-radius: 2px;
  background-color: rgba($primary-color, 1);
  margin: 3px 0 3px 10px;
}

.title {
  color: rgba(#fff, 0.5);
  align-self: center;
  margin-left: 10px;
}

.value {
  flex: auto;
  font-size: 15px;
  font-weight: 100;
  color: rgba(#fff, 1);
  align-self: center;
  //margin-left: 15px;
  //margin-right: 10px;
  margin: 7px 10px 7px 15px;
  padding: 1px 2px 1px 2px;
  display: flex;
  justify-content: flex-end;
  //background-color: rgba($secondary-color, 0.25);
  //background-image: radial-gradient(circle, rgba($secondary-color, 0.25) 0%, rgba($secondary-color, 0.5) 100%);
}

.icon {
  align-self: center;
  justify-self: center;
  font-size: 20px;
  //color: rgba($primary-color, 0.85);
}
