.main {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 75px 1fr;
}

.overlay {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 18px;
}

.header {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 0px;
}

.toolbar {
  flex: none;
  width: 100px;
  color: #adadad;
}

.slider_container {
  flex: auto;
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  padding-left: 5px;

  &_title {
    flex: none;
    color: #adadad;
    font-weight: 400;
    align-self: center;
  }

  &_slider {
    flex: auto;
    margin-left: 5px;
    margin-bottom: 10px;
    align-self: center;
  }
}

.grid {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: 3;
}
