.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.form {
  flex: none;
}

.html_editor {
  flex: auto;
  margin: 25px 0px 10px 0px;
  height: 20px;
}

.buttons {
  flex: none;
  height: 25px;
  display: flex;

  &_left {
    flex: auto;
    display: flex;
    justify-content: flex-start;
  }

  &_right {
    flex: auto;
    display: flex;
    justify-content: flex-end;
  }
}
