.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container {
  flex: auto;
  height: 100px;
}
