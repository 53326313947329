.box {
  //@include border();
  //background-color: $tab-color;
  //border: 1px solid #adadad;
  display: flex;
  justify-content: center;
  align-items: center;
  //font-size: 16px;
  text-align: center;
  cursor: pointer;

  &:hover {
    opacity: 0.5;
    //@warn: #adadad;
    //color: black;
  }
}

.grid {
  height: 100%;
}
