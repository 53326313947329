.main {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chart {
  flex: auto;
  padding: 10px;
  align-self: center;
}

.flexrow {
  height: 100%;
  display: flex;

  &_item {
    flex: auto;
  }
}

.title {
  flex: none;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: rgba(#fff, 0.5);
  font-weight: 400;
  border-bottom: 1px solid rgba(#fff, 0.1);
}
