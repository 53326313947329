@import "../palette.scss";

.theme-toolbar {
  .dx-texteditor-input {
    font-size: 14px;
    color: $primary-text-color !important;
  }

  .dx-toolbar-items-container {
    height: 40px;
  }

  .dx-item.dx-toolbar-item {
    color: $secondary-text-color;
  }

  .dx-button-content .dx-icon {
    color: $primary-color;
    font-size: 17px;
  }
}
