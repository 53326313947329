.main {
  height: 100%;
  padding-top: 10px;
}

.title {
  font-size: 28px;
  margin-bottom: 20px;
}

.cancelbutton {
  margin-left: 10px;
}

.indicator {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: auto;
  //margin-left: 25%;
  //margin-right: auto;
}

.error {
  flex: 1;
  margin-left: 25%;
  margin-right: auto;
  background-color: #f44336;
  min-height: 25px;
  padding-left: 25px;
  padding-right: 25px;
}
