@import "theme/palette.scss";

.main {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $surface-color;
}

.header {
  flex: none;
  height: 75px;
  background-color: $menu-color;
  display: flex;
  align-items: center;
  padding-left: 10px;
  margin: 25px 25px 0px 25px;
  // color: black;

  &_avatar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: auto;
    height: 50px;
    background-size: cover;
    padding: 0px;
  }

  &_subject {
    flex: none;

    &_title {
      font-size: 24px;
      font-weight: 500;
      padding-left: 25px;
      color: $primary-color;
    }

    &_createdby {
      display: flex;
      padding-left: 25px;

      &_name {
        font-size: 14px;
        color: $secondary-color;
      }

      &_org {
        font-size: 14px;
        margin-left: 5px;
        color: $secondary-text-color;
      }
    }
  }

  &_createdon {
    flex: auto;
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    color: $secondary-text-color;
  }
}

.body {
  flex: auto;
  margin: 20px 0px 0px 15px;
}

.comments {
  flex: auto;
  border-left: 1px solid gray;
  margin: 25px 25px 25px 50px;

  &_header {
    font-size: 18px;
    font-weight: 500;
    padding-left: 10px;
    color: $primary-color;
  }

  &_body {
    margin-top: 25px;
    //padding-left: 10px;
  }
}

.footer {
  flex: none;
  height: 30px;
  background-color: $menu-color;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 10px;
  margin: 0px 25px 25px 25px;
}
