@import "theme/palette.scss";

.main {
  height: 100%;
  display: flex;
}

.tickets {
  flex: none;
  width: 800px;
}

.messages {
  flex: auto;
  margin-left: 10px;
}
