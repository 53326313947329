.main {
  height: 100%;
  display: flex;
}

.activity {
  flex: auto;
  //width: 600px;
}

.phase {
  flex: auto;
  margin-left: 10px;
}
