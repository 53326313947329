@import "theme/palette.scss";

.helper_gis_active-Button {
  background: $primary-color;
  color: black;
}

#helper_gis_rating {
  //display: flex;
  height: 100%;
  //width: 100%;
  //visibility: hidden;
}

.helper_gis_rating-item {
  //width: 100%;
  padding: 5px;
  //text-align: center;
  //vertical-align: baseline;
  //cursor: pointer;
  //height: 40px;
}

.helper_gis_rating-item:focus {
  background-color: dimgrey;
}

.helper_gis_rating-item:hover {
  background-color: dimgrey;
}

.helper_gis_rating-item_selected {
  background: $primary-color;
  color: black;
}

#helper_gis_rating-title {
  font-size: 12pt;
  font-weight: 60;
  padding: 10px;
  color: black;
}

#helper_gis_infoDiv {
  background-color: white;
  padding: 10px;
  width: 260px;
  margin: 5px;
  //position: absolute;
  bottom: 15px;
  right: 10px;
  font-size: 14px;
  //display: none;
}

.helper_gis_geometry-options {
  display: flex;
  flex-direction: row;
}

.helper_gis_geometry-button {
  flex: 1;
  border-style: solid;
  border-width: 1px;
  border-image: none;
}

.helper_gis_geometry-button-selected {
  background: #4c4c4c;
  color: #fff;
}

.helper_gis_options {
  max-width: 260px;
  width: 100%;
  height: 25px;
}

.helper_gis_loading {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
}
