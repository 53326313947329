.main {
  height: 100%;
}

.indicator {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.image {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: none;
}

.icon {
  flex: auto;
  font-size: 50px;
}

.text {
  flex: auto;
  font-size: 12px;
  text-align: center;
}
