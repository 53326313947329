@import "../palette.scss";

.theme-treeview {
  //
  // rows

  .dx-treeview {
    background-color: $surface-color;
  }

  .dx-treeview-node {
    color: rgba(255, 255, 255, 0.7);

    &[aria-level="1"] {
      font-weight: 400;
      font-size: 14px;
      border-top: 1px solid $line-color;

      //border-bottom: 1px solid $border-color;
      .dx-icon {
        font-size: 14px;
      }
    }
  }

  .dx-treeview-node-container {
    .dx-treeview-node {
      &.dx-state-selected:not(.dx-state-focused) > .dx-treeview-item {
        background: transparent;
      }

      &.dx-state-selected > .dx-treeview-item * {
        color: $primary-color;
        //color: #009688;
        //@extend .dx-theme-accent-as-text-color;
      }

      &:not(.dx-state-focused) > .dx-treeview-item.dx-state-hover {
        background-color: $line-color;
      }
    }
  }
}
