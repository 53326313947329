.components_progress {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;

  &_container {
    grid-row: 1/2;
    grid-column: 1/2;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  &_circle {
    margin-top: -100px;
    grid-row: 1/2;
    grid-column: 1/2;
    text-align: center;
    align-self: center;
    justify-self: center;
    width: 75px;
    //color: #adadad;
  }

  &_text {
    grid-row: 1/2;
    grid-column: 1/2;
    margin-top: 0px;
    text-align: center;
    align-self: center;
    justify-self: center;
    //width: 75px;
    color: #adadad;
  }

  &_button {
    grid-row: 1/2;
    grid-column: 1/2;
    margin-top: 5px; // 55px
    text-align: center;
    align-self: center;
    justify-self: center;
    //width: 75px;
  }

  // &_button {
  //   grid-row: 2/3;
  //   grid-column: 1/3;
  //   font-size: 10px;
  //   padding-top: 0px;
  //   align-self: end;
  //   justify-self: end;
  // }
}

.dx-button-has-text .dx-button-content {
  padding: 0px 10px 3px;
  font-size: 10px;
}
