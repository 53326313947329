.main {
	height: 100%;
	display: flex;
}

.resourcegroup {
	flex: 0 0 350px;
	width: 350px;
}

.resource {
	flex: 1;
	margin-left: 10px;
}
