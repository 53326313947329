.main {
  height: 100%;
}

.indicator {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@import "theme/palette.scss";

.image {
  height: 100px;
  width: 100px;
  object-fit: cover;
}
